<template>
  <b-overlay :show="fetching" rounded="sm">
    <div class="page">
      <div class="page__title">
        {{ getModuleConfig("staff_specific_page.intro.form_title") }}
      </div>
      <div class="page__desc text-center">
        {{ getModuleConfig("staff_specific_page.intro.form_desc") }}
      </div>
      <div class="field" v-if="isCanBinding">
        <div class="sales__wrapper">
          <swiper v-if="staffs && showAvatar" :options="swiperOption" class="sales align-items-stretch" >
            <swiper-slide  v-for="(i, idx) in staffs" :key="idx" class="sales__item">
              <div class="card text-center d-flex flex-column">
                <div>
                  <img
                    v-if="displayAvatar(i)"
                    :src="displayAvatar(i)"
                    class="card-img-top"
                  />
                  <img
                    v-else
                    src="./default_staff_img.jpg"
                    class="card-img-top"
                  />
                </div>

                <div class="p-3 flex-fill d-flex flex-column">
                  <div class="flex-fill">
                  <table v-if="staffFields.length != 0" class="mx-auto my-3 align-text-top" >
                    <tr v-for="(field, index) in staffFields" :key="index">
                      <template v-if="field.title">
                        <td class="font-weight-bold text-nowrap align-text-top">{{ field.title }}</td>
                        <td class="text-left align-text-top">{{ displayField(i, field) }}</td>
                      </template>
                      <template v-else>
                        <td colspan="2" class="align-text-top">{{ displayField(i, field) }}</td>
                      </template>
                    </tr>
                  </table>
                  <template v-else>
                    <div>{{ i.name }}</div>
                  </template>
                </div>
              </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div v-else>
        <div
          class="page__content"
          v-if="getModuleConfig('staff_specific_page.multiple_bind.forbid_text')"
          v-html="getModuleConfig('staff_specific_page.multiple_bind.forbid_text')"
        ></div>
      </div>
    </div>

    <div class="page__button s-space-y-4">
      <MemberButton
        :button-style="`s-btn-outline-primary`"
        v-if="getModuleConfig('staff_specific_page.intro.button_text') && isCanBinding"
        :button-text="getModuleConfig('staff_specific_page.intro.button_text')"
        :button-url="getModuleConfig('staff_specific_page.intro.button_url')"
        :type="getModuleConfig('staff_specific_page.intro.button_action')"
      >
      </MemberButton>
      <MemberButton
        :button-style="`s-btn-outline-primary`"
        v-if="getModuleConfig('staff_specific_page.multiple_bind.button_text') && !isCanBinding"
        :button-text="getModuleConfig('staff_specific_page.multiple_bind.button_text')"
        :button-url="getModuleConfig('staff_specific_page.multiple_bind.button_url')"
        :type="getModuleConfig('staff_specific_page.multiple_bind.button_action')"
      >
      </MemberButton>
    </div>
  </b-overlay>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import { mapGetters } from "vuex";
import staffApi from "@/apis/liff/v2/staff";
import mixinStaff from "@/mixins/liff/staff";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  mixins: [mixinStaff],
  computed: {
    ...mapGetters("liffGeneral", ["liffId"]),
    ...mapGetters({
      getModule: "liffModule/getModule",
    }),
    showAvatar() {
      return (
        this.getModuleConfig("staff_specific_page.intro.show_avatar") ??
        false
      ) && !this.fetching;
    },
    staffFields() {
      return (
        this.getModuleConfig("staff_specific_page.staffs_list.fields") ?? []
      ).sort((a, b) => a.order - b.order);
    },
    displayButtonText() {
      const text = this.getModuleConfig(
        "staff_specific_page.intro.button_text"
      );
      if (text) {
        return this.templateReplace(text, { staff: this.selectedStaff });
      } else {
        return this.getModuleConfig(
          "staff_specific_page.bind_button.button_default_text"
        );
      }
    },
    staffId() {
      return this.$route.params.staffId;
    },
    swiperOption() {
      // todo single one how to show
      return {
        //loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        wrapperClass:'swiper-wrapper',
        //width: 220, // 不知為啥 他是 px*2數字 然後會扣掉spaceBetween(px) 才會是他Card Width
        spaceBetween: 10,
        breakpoints: {
          768: {
            //width: undefined,
            spaceBetween: 30,
          },
        },
      }
    }
  },
  components: {
    MemberButton,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      fetching: true,
      selectedStaff: {
        id: null,
      },
      staffs: [],
      lineData: {},
      fetchingStaff: false,
      isCanBinding: true
    };
  },
  watch: {},

  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.checkBinding();
      if (!this.isCanBinding) {
        this.fetching = false;
        return
      }
      if (this.getModuleConfig("staff_specific_page.intro.redirect_to_oa")) {
        // 如果可以綁定 但是有開啟轉導到OA
        window.location.href = `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/redirect-to-oa`;
      }
      await this.fetchStaff();
      this.handleSubmit()
      this.fetching = false;
    },
    async checkBinding() {
      await staffApi.checkBinding().then((res) => {
        // 如果綁定 且可以多重綁定
        if (res.isBound) {
          if (this.getModuleConfig("staff_specific_page.intro.multiple_bind.enable")) {
            this.isCanBinding = true
          } else {
            this.isCanBinding = false
          }
        } else {
          this.isCanBinding = true
        }
      });
    },
    async fetchStaff() {
      this.fetching = true;
      await staffApi.getStaff(this.staffId).then((res) => {
        this.staffs = [res]
        this.selectedStaff = res
        this.fetching = false;
      });
    },
    async handleSubmit() {
      try {
        this.fetching = true;
        await this.doSubmit();
      } catch (error) {
        this.fetching = false;
      }
    },
    doSubmit() {
      return new Promise(() => {
        staffApi
          .sendBindTemplate({
            sid: this.staffId,
          })
      });
    },
    displayAvatar(staff) {
      return (
        staff.avatar_url ??
        this.getModuleConfig(
          "staff_specific_page.staffs_list.default_avatar_url"
        )
      );
    },
    displayField(staff, field) {
      if (field.type == "date") {
        return this.$moment(staff[field.matched_key]).format("YYYY-MM-DD");
      }
      return staff[field.matched_key] ?? "";
    },
    selectStaff(staff) {
      this.selectedStaff = staff;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
.sales {
  margin: 5px -16px;
  padding: 16px 0;

  &__wrapper {
    //min-height: 460px;
  }

  &__button {
    opacity: 0.5;
    &__active {
      opacity: 1;
    }
  }

  &__item {
    width: 220px;
    height: revert;

    .card {
      border-radius: 0.5rem;
      height: 100%;

      .card-img-top {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
    }
  }

  ::v-deep.swiper-wrapper {
    align-items: stretch;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: gray;
    background: white;
    border-radius: 100%;
    aspect-ratio: 1;
    width: auto;
    &::after {
      font-size: 1.5rem;
    }
  }
}

.page {
  padding: 5px 5px;
}

::v-deep .spinner-border {
  border-width: 0.2em !important;
}
</style>
